<template>
    <div class="attente">
        <div class="w-full flex justify-around items-end max-w-7xl min-w-login m-auto mt-36">
            <div class="w-full max-w-xs text-left p-4 justify-self-end">
                <h1 class="text-loginBlue font-bold text-3xl pb-5">Rappel instructions</h1>
                <h3 class="font-light text-base pb-2 text-loginBlue ">Durée :<strong> 2H</strong> </h3>
                <p class="text-xs font-bold">L’épreuve est découpée en quatre blocs, à réaliser dans l’ordre :</p>
                <ol>
                    <li><span class="text-loginBlue font-bold">1</span> Le rôle du système de classification ;</li>
                    <li><span class="text-loginBlue font-bold">2</span> La fiche descriptive d’emploi ;</li>
                    <li><span class="text-loginBlue font-bold">3</span> La cotation et le classement de l’emploi ;</li>
                    <li><span class="text-loginBlue font-bold">4</span> La méthode de mise en oeuvre.</li>
                </ol>
                <p class="text-xs font-bold">Chaque phase comprend une partie <strong>Questions (QCM)</strong>, suivie
                    d’une partie
                    <strong>Étude(s) de cas.</strong>
                </p>
                <p class="text-xs font-bold text-red-700">Attention, une fois votre réponse validée,
                    vous ne pourrez plus revenir en arrière.</p>
            </div>
            <div
                class="w-full max-w-xs bg-gradient-to-r from-gradientTop to-gradientBottom rounded text-left p-4 justify-self-end text-white">
                <h1 class="text-white capitalize">CCPM Classification</h1>
                <hr>
                <div class="loader">
                    <SpinnerBlock />
                </div>
                <h2>Veuillez patienter,</h2>
                <span>la session n'a pas encore démarré</span>

            </div>
        </div>

        <button
            class="bg-loginBlue hover:bg-blue-700 text-white font-bold py-2 px-4 h-1/2 rounded-full w-52 focus:outline-none focus:shadow-outline">Démarrer</button>

    </div>
</template>

<script>
import dayjs from 'dayjs'
import SpinnerBlock from "@/components/commun/SpinnerBlock.vue";


export default {
    name: 'MenuAttente',
    data: function () {
        return {
        }
    },
    components: {
        SpinnerBlock

    },
    methods: {
        setEndHour() {
            let hour = dayjs().add(2, 'hour').format('HH:mm');
            this.endHour = hour
            this.$store.dispatch('changeUserSessionStatus', 'started')

        },
    },
}
</script>