<template>
    <div v-bind:class="{ 'bg-white': mode === 'on', 'border-loginBlue': mode === 'on', 'bg-gray-200': mode === 'locked', 'border-gray-200': mode === 'locked' }"
        class="card mb-3 rounded-r-full rounded-l-3xl border-2 border-loginBlue flex w-full justify-between items-center pr-8">
        <div v-if="mode === 'on'" class="card-number flex-row pt-2 items-center text-white bg-loginBlue h-full">
            <span></span><br>
            <span class="font-bold text-2xl">{{ numb }}</span>
        </div>
        <div v-else-if="mode === 'locked'" class="card-number flex-row pt-2 items-center text-white bg-gray-400 h-full">
            <span></span><br>
            <span class="font-bold text-2xl">{{ numb }}</span>
        </div>
        <div class="titleBtn flex-auto text-left pl-4">
            <span class="font-bold">{{ titre }}</span><br>
            <span class="font-light">Durée estimée : {{ time }} minutes</span>
        </div>
        <router-link to="/game" v-if="mode === 'on' && stateDone==='none'">
            <button
                class="bg-loginBlue hover:bg-blue-700 text-white font-bold py-2 px-4 h-1/2 rounded-full w-52 focus:outline-none focus:shadow-outline">Démarrer</button>
        </router-link>

        <div v-else-if="mode === 'locked'"
            class="bg-gray-400 text-white font-bold py-2 px-4 h-1/2 rounded-full w-52 focus:outline-none focus:shadow-outline">
            <img alt="Lock" class="m-auto" src="@/assets/lock.png">
        </div>
        <div v-else-if="mode === 'on' && stateDone==='done'"
            class="bg-gray-400 text-white font-bold py-2 px-4 h-1/2 rounded-full w-52 focus:outline-none focus:shadow-outline">
            <!-- <img alt="Lock" class="m-auto" src="@/assets/lock.png"> -->
            Terminée
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: 'CardMenu',
    emits: ["launchQcm"],
    data: function () {
        return {
        }
    },
    props:
        ['titre', 'time', 'mode', 'numb', 'stateDone']
    ,
    computed: {
        ...mapState(['status'])
    }

}
</script>

<style scoped>
.card {
    height: 100px;
    border-radius: 10px 50px 50px 10px;
}

.card-number {
    height: 100px;
    width: 100px;
    border-radius: 10px 0px 0px 10px;
}
</style>