<template>
    <div class="m-auto game-capsule" v-if="downloaded">
        <div
            style="font-family:Barlow; visibility: hidden; font-weight: bold; z-index: -1; position: absolute; top: 0; left:0">
            .</div>
        <div
            style="font-family:LexendBold; visibility: hidden; font-weight: bold; z-index: -1; position: absolute; top: 0; left:0">
            .</div>
        <div
            style="font-family:LexendMedium; visibility: hidden; font-weight: bold; z-index: -1; position: absolute; top: 0; left:0">
            .</div>
        <div
            style="font-family:LexendSemiBold; visibility: hidden; font-weight: bold; z-index: -1; position: absolute; top: 0; left:0">
            .</div>
        <div
            style="font-family:LexendLight; visibility: hidden; font-weight: bold; z-index: -1; position: absolute; top: 0; left:0">
            .</div>
        <div
            style="font-family:SligoilMicro; visibility: hidden; font-weight: bold; z-index: -1; position: absolute; top: 0; left:0">
            .</div>
        <div :id="containerId" class="" />
    </div>
    <div class="placeholder m-auto" v-else>
        <div v-if="!empty">
            <div class="">
                <SpinnerBlock />
            </div>
            <span class="text-xl text-white font-semibold pt-10">Downloading...</span>
        </div>
        <div v-else-if="empty">
            <div class="h-screen w-full flex items-center justify-center m-auto">
                <div class="h-screen w-full bg-loginBlue bg-opacity-90 m-auto pt-48">
                    <span class=" text-white w-full text-4xl font-bold">Un problème est survenu.</span> <br>
                    <br><br><br>
                    <span class=" text-white w-full text-2xl font-semibold">Merci de cliquer ci-dessous sur le bouton
                        pour vous reconnecter.</span> <br>
                    <!-- <span class=" text-white w-full text-2xl font-semibold">Nous vous invitons à vous déconnecter en cliquant
                sur le bouton "Déconnexion".</span> -->
                    <br> <br><br><br><br><br>
                    <button @click="logOut()"
                        class=" bg-blue-700 text-white hover:bg-white hover:text-blue-700 font-bold py-2 px-4 rounded-full w-52 focus:outline-none focus:shadow-outline"
                        type="button">
                        Déconnexion
                    </button>
                </div>

            </div>

        </div>
    </div>
    <FooterGame />
</template>

<script>
import { mapState } from 'vuex'
import FooterGame from '@/components/commun/FooterGame.vue';
import SpinnerBlock from '@/components/commun/SpinnerBlock.vue';

export default {
    name: 'gameCaps',
    data: function () {
        return {
            downloaded: false,
            gameInstance: null,
            containerId: 'game-container',
            vueScop: this,
            empty: false
        }
    },
    components: { FooterGame, SpinnerBlock },
    methods: {
        async init() {
            this.$store.dispatch('getCurrentQuestionData')
            let game = await import('../../games/app');
            let vueData = this.currentQuestionData
            vueData.vueScop = this.vueScop
            vueData.qcmStepCount = this.qcmStepCount
            if (this.currentQuestionData.questions !== undefined) {
                this.downloaded = true;
                this.$nextTick(() => {

                    this.gameInstance = game.launchGame(this.containerId, vueData, this.gameActive)
                    if (!this.gameActive) {
                        this.$store.dispatch('defineGameActive', true)
                    }
                })
            } else {
                this.downloaded = false
                this.empty = true

            }
        },
        endGame() {
            this.downloaded = false
            this.$store.dispatch('defineGameActive', false)
        },
        logOut: function () {
            this.$store.dispatch('logout', {
            })
        },
    },
    computed: {
        ...mapState(['activityAnswer', 'currentQuestionData', 'qcmStepCount', 'gameActive']),
    },
    mounted() {
        this.init()
    },
    onUnmounted() {
        this.downloaded = false
        // this.gameInstance.destroy(true, true)
    }
}
</script>

<style scoped>
canvas {
    margin-top: 0px !important;
    max-width: 50% !important;
}

.placeholder {
    height: 80vh;
}
</style>