<template>
  <div class="w-5/6 flex justify-around items-end m-auto login-container">
    <div>
      <h1 class="text-4xl text-white font-bold">CCPM Classification</h1>
    </div>
    <div class="login w-full bg-white rounded text-left p-4 justify-self-end">
      <h1 class="text-loginBlue font-bold text-3xl pb-5">Se connecter</h1>
      <h3 class="font-light text-base pb-2" v-if="loginStatus === ''">Veuillez saisir ci-dessous :</h3>
      <h3 class="font-light text-base pb-2 text-red-700" v-else-if="loginStatus === 'error_login'"><img alt="Vue logo"
          src="@/assets/caution.png" class=" h-4">Votre identifiant ou votre mot
        de passe sont incorrect
      </h3>
      <h3 class="font-light text-base pb-2 text-red-700" v-else-if="loginStatus === 'not_registred'"><img alt="Vue logo"
          src="@/assets/caution.png" class=" h-4">Vous n'avez pas encore été émargé par le surveillant
      </h3>
      <SpinnerBlock v-else-if="loginStatus === 'loading'" />
      <form class="">
        <div class="mb-4">
          <label class="block text-loginBlue text-sm" for="username">
            Votre <strong>code de connexion candidat</strong>
          </label>
          <input v-model="username"
            class="appearance-none border-b-2 border-solid border-loginBlue w-full py-1  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username" type="text" placeholder="Exemple : idJ78fw7s">
        </div>
        <div class="mb-6">
          <label class="block text-loginBlue text-sm" for="password">
            Votre <strong>mot de passe</strong>
          </label>
          <input v-model="password"
            class="appearance-none border-b-2 border-solid border-loginBlue w-full py-1  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="password" type="password" placeholder="Exemple : 24564">
        </div>
        <div class="flex items-center mb-9">
          <button v-if=btnStateSeter @click="login()"
            class="bg-loginBlue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full w-52 focus:outline-none focus:shadow-outline"
            type="button">
            Valider
          </button>
          <button v-else-if=!btnStateSeter class="bg-gray-200 text-gray-500 font-bold py-2 px-4 rounded-full w-52"
            type="button">
            Valider
          </button>
        </div>
      </form>
      <p class="text-xs p-3">Votre code de connexion et votre mot de passe sont des informations personnelles uniques
        reçues dans le mail de confirmation d’inscription. <br>
        Si vous avez oublié l’un ou l’autre, vous pouvez les demander à l’examinateur de la session.</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SpinnerBlock from "@/components/commun/SpinnerBlock.vue";

export default {
  name: 'LoginStart',
  // emits: ["set-user"],
  data: function () {
    return {
      username: '',
      password: '',
      btnState: false,
    }
  },
  components: {
    SpinnerBlock
  },
  methods: {
    login: function () {
      this.$store.dispatch('login', {
        username: this.username,
        password: this.password
      })
    }
  },
  computed: {
    btnStateSeter() {
      if (this.username !== '' && this.password !== '') {
        return true
      } else {
        return false
      }
    },
    ...mapState(['loginStatus'])
  }
}
</script>

<style scoped>
  .login {
    height: 32rem;
    max-width: 25rem;
  }
  
  </style>