<template>
    <div class="full-container h-screen bg-gradient-to-tr from-gradientTop to-gradientBottom">
        <div class=" game-container m-auto">
            <GameCaps />
        </div>
    </div>
</template>

<script >
import GameCaps from '@/components/game/GameCaps'

export default {
    name: 'GameView',
    components: { GameCaps },
    mounted() {
        this.$store.dispatch('setStartProgression')
    },
}
</script>

<style scoped>
    .game-container {
        width: 90%;
        align-self: center;
        padding-top: 3rem;
    }
    
    @media (min-width: 1400px) {
        .game-container {
            width: 70%;
            align-self: center;
            padding-top: 3rem;
        }
    
    }
    @media (max-height: 810px) {
        .game-container {
            width: 75%;
            align-self: center;
            padding-top: 1rem;
        }
    }
    @media (max-height: 760px) {
        .game-container {
            width: 70%;
            align-self: center;
            padding-top: 1rem;
        }
    }
    @media (max-height: 630px) {
        .game-container {
            width: 60%;
            align-self: center;
            padding-top: 0.5rem;
        }
    }
    </style>