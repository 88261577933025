<template>
    <div class="skip">
        <div class="">
            <SpinnerBlock />
        </div>
        <span class="text-xl text-white font-semibold pt-10">Downloading...</span>
    </div>
</template>
<script>
import router from '../router/index';
import SpinnerBlock from '@/components/commun/SpinnerBlock.vue';
export default {
    name: 'SkipView',
    components: { SpinnerBlock },
    methods: {
        reroute() {
            router.push('/game')
        }
    },
    mounted() {
        this.reroute()
    },
}
</script>

<style>
.skip {
    height: 80vh;
}
</style>