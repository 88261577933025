import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MenuView from '../views/MenuView.vue'
import GameView from '../views/GameView.vue'
import SkipView from '../views/SkipView.vue'

const routes = [{
        path: '/',
        name: 'login',
        component: LoginView
    },
    {
        path: '/menu',
        name: 'menu',
        component: MenuView
    },
    {
        path: '/game',
        name: 'game',
        component: GameView
    },
    {
        path: '/skip',
        name: 'skip',
        component: SkipView
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router