<template>
    <div v-if="this.userSessionStatus === 'started'" class="page h-screen">
        <div class="title my-8 flex w-full items-end">
            <div class="line h-1 w-full mb-2 bg-loginBlue"></div>
            <h1 class="text-loginBlue w-full text-3xl xl:text-4xl title">CCPM CLASSIFICATION</h1>
            <div class="line h-1 w-full mb-2 bg-loginBlue"></div>
        </div>
        <div class="btnPart w-2/3 m-auto">
            <h3 class="text-left text-loginBlue font-bold pb-4 text-2xl">Déroulé de l'épreuve</h3>

            <CardMenu titre="Le rôle du système de classification " time="25 " mode="on" numb="1"
            v-bind:stateDone="currentQuestionData.number >= 11 ? 'done' : 'none'"
                @launchQcm="selectGame()" />
            <CardMenu titre="La fiche descriptive d’emploi" time="35 "
                v-bind:mode="currentQuestionData.number >= 11 ? 'on' : 'locked'" numb="2"
                v-bind:stateDone="currentQuestionData.number >=  24? 'done' : 'none'"
                @launchQcm="selectGame()" />
            <CardMenu titre="La cotation et le classement de l’emploi" time="35 "
                v-bind:mode="currentQuestionData.number >= 24 ? 'on' : 'locked'" numb="3"
                v-bind:stateDone="currentQuestionData.number >= 38 ? 'done' : 'none'"
                @launchQcm="selectGame()" />
            <CardMenu titre="La méthode de mise en oeuvre" time="25 "
                v-bind:mode="currentQuestionData.number >=  38 ? 'on' : 'locked'" numb="4"
                v-bind:stateDone="currentQuestionData.number >=  49 ? 'done' : 'none'"
                @launchQcm="selectGame()" />
        </div>
        <FooterGame />
    </div>
    <div v-else-if="this.userSessionStatus === 'wait'" class="h-screen">
        <MenuAttente />
    </div>
    <div v-else-if="this.userSessionStatus === 'finished'" class="page h-screen">
        <EndMenu />
    </div>
    <div v-else-if="this.userSessionStatus === 'timeOut'" class="page h-screen">
        <TimeOutMenu/>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import MenuAttente from "@/components/menu/MenuAttente.vue";
import FooterGame from "@/components/commun/FooterGame.vue";
import CardMenu from "@/components/menu/CardMenu.vue";
import EndMenu from "@/components/menu/EndMenu.vue";
import TimeOutMenu from "@/components/menu/TimeOutMenu.vue";

export default {
    name: 'MenuView',
    components: { FooterGame, CardMenu, EndMenu, MenuAttente, TimeOutMenu },
    data: function () {
        return {
            nowTime: "",
            step: 'wait'
        }
    },
    methods: {
        selectGame: function () {
            this.$store.dispatch('getCurrentQuestionData', {
            })
        },
        getUser: function () {
          if(this.user.firstname === ''){
            this.$store.dispatch('getCurrentUser')
          }
        },

    },
    computed: {
        setName() {
            return this.user.data.firstname + ' ' + this.user.data.lastname
        },
        ...mapState(['user', 'qcmStepCount', 'userSessionStatus', 'currentQuestionData'])
    },
    mounted() {
        this.selectGame()
        this.getUser()
        this.$store.dispatch('setStartProgression')
    },
}
</script>

<style scoped>
    .page {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* height: -webkit-fill-available; */
    }
    
    footer {
        margin-top: 2rem;
    }
    
    
    @media (max-height: 670px) {
        .page {
            transform: scale(0.8) translateY(-2rem);
        }
    }
    
    @media (max-height: 580px) {
        .page {
            transform: scale(0.6) translateY(-4rem);
        }
    }
    
    .btnPart {
        min-width: 40rem;
        max-width: 60rem;
    }
    </style>