<template>
    <footer class="flex justify-between items-center text-white bg-black w-full h-10">
        <span class="md2:w-1/3 w-1/4">{{ setName }}</span>
        <div class="md2:w-1/3 w-1/4 flex items-center">
            <div class="w-full bg-black h-2 border-2 border-white flex mr-2">
                <div class="bg-white h-1" v-bind:style="{ width: manageProgression() }"></div>
            </div>
            <span>{{ manageProgression() }}</span>
        </div>
        <div class="time-section md2:w-1/3 w-1/2 flex justify-around ">
            <span>Fin de session : {{this.finishHour}}</span>
            <div class=" h-5 w-20 bg-white text-black">{{ timing }}</div>
        </div>
    </footer>
</template>

<script>
import dayjs from 'dayjs'
import Cookies from 'js-cookie';
import { mapState } from 'vuex'
var hourEnd = Cookies.get('finishTime')
export default {
    name: 'FooterGame',
    data: function () {
        return {
            footerProgression: '0%',
            timing: '',
            endHour: hourEnd
        }
    },
    methods: {
        setDate() {
            setInterval(() => {
                this.timing = dayjs().format('HH:mm');
            }, 1000);
        },
        setEndHour() {
            var hour
            if (this.user.with_disability) {
                hour = dayjs().add(2.5, 'hour').format('HH:mm');
            } else {
                hour = dayjs().add(2, 'hour').format('HH:mm');
            }
            this.endHour = hour
            this.$store.dispatch('defineFinishTime', hour)
        },
        manageProgression() {
            var value
            if (this.progression === undefined && this.currentQuestionData.number === undefined) {
                value = 0
            } else if (this.progression === undefined && this.currentQuestionData.number !== undefined) {
                value = this.currentQuestionData.number
            } else {
                value = this.progression
            }
            let valueInPercent = value * 2
            let valueInString = valueInPercent + '%'
            return valueInString
        },
    },
    computed: {
        setName() {
            let fullName
            if (this.user.fullName !== '') {
                fullName = this.user.fullName
            } else {
                this.$store.dispatch('getCurrentUser')
                fullName = this.user.fullName
            }
            return fullName
        },
        ...mapState(['user', 'progression', 'finishHour', 'currentQuestionData'])
    },
    beforeMount() {
        this.setDate()
        this.manageProgression()
        this.setEndHour()
    },
}
</script>

<style scoped>
    @media (max-height: 810px) {

        #progressBar{
            width:33%;
        }
        #userName{
            width:33%;
        }
        #timeSection{
            width:33%;
        }
    }
    @media (max-height: 630px) {
        #endSession{
            font-size: 13px;
        }
    }
</style>