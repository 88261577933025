<template>
  <div class="w-5/6 justify-center items-end min-w-login m-auto login-container">
    <div class="login w-full bg-white rounded text-left p-4 justify-self-end">
      <h1 class="text-loginBlue font-bold text-3xl pb-5">Instructions</h1>
      <p class=" text-base py-3 text-gray-900 ">Vous vous apprêtez à passer l’épreuve du CCPM
        classification. Vous n’avez le droit à aucune ressource pour vous aider.</p>
      <p class=" text-base py-3 text-gray-900">L’épreuve va durer 2h. Si vous êtes en situation de handicap et que vous
        souhaitez
        disposer d’un temps supplémentaire, signalez-le à l’examinateur, avec un justificatif.
      </p>
      <p class=" text-base py-3 text-gray-900">L’épreuve est découpée en <strong>4 phases</strong>, à
        réaliser dans l’ordre. Chaque phase comprend une partie questions, sous forme de QCM, et une partie études de
        cas.</p>
      <p class=" text-base py-3 text-gray-900"><strong>ATTENTION !</strong> Une fois votre réponse validée,
        vous ne pouvez pas revenir en arrière. <strong>Ne cliquez en aucun cas sur la flèche “retour” du
          navigateur.</strong></p>
      <p class="text-base py-3 font-bold text-red-600">En cas de bug, suivez simplement les indications données par la
        plateforme. En cas de coupure d’électricité ou de coupure EDF, soyez rassurés, vos réponses sont enregistrées.
        Vous pourrez vous reconnecter et reprendre où vous en étiez.</p>
      <button
        class="bg-loginBlue hover:bg-blue-700 text-white font-bold py-2 mt-8 px-4 rounded-full w-52 focus:outline-none focus:shadow-outline"
        @click="gotocertif()" type="button">
        J'ai compris
      </button>
    </div>
    <h1 class="text-4xl text-white font-bold titre-ccpm">CCPM Classification</h1>
  </div>
</template>


<script>
export default {
  name: 'LoginInstructions',
  methods: {
    gotocertif(){
      this.$store.dispatch('goToCertif',{})
    }
  }
}
</script>

<style scoped>

  .titre-ccpm {
    text-align: left;
    padding-top: 1rem;
    margin: auto;
  }
  
  .login-container {
    max-width: 59rem;
  }
  </style>