<template>
    <div class="title-cont flex w-full items-end relative">
        <div class="line h-1 w-full mb-2 bg-loginBlue"></div>
        <h1 class="text-loginBlue w-full text-3xl xl:text-4xl title">CCPM CLASSIFICATION</h1>
        <div class="line h-1 w-full mb-2 bg-loginBlue"></div>
    </div>
    <div class="btnPart w-2/3 h-3/5 m-auto ">
        <h3 class="text-left text-loginBlue font-bold pb-4 text-2xl">Déroulé de l'épreuve</h3>

        <CardMenu titre="Le rôle du système de classification " time="25 " mode="on" numb="1" gameRef="activiteQcm"
            stateDone='done' @launchQcm="selectGame()" />
        <CardMenu titre="La fiche descriptive d’emploi" time="35 " mode="on" numb="2" gameRef="activiteQcm"
            stateDone='done' @launchQcm="selectGame()" />
        <CardMenu titre="La cotation et le classement de l’emploi" time="35 " mode="on" numb="3" gameRef="activiteQcm"
            stateDone='done' @launchQcm="selectGame()" />
        <CardMenu titre="La méthode de mise en oeuvre" time="25 " mode="on" numb="4" gameRef="activite2"
            stateDone='done' @launchQcm="selectGame()" />
    </div>
    <div class="h-screen w-full absolute flex items-center justify-center m-auto">
        <div class="h-screen w-full bg-loginBlue bg-opacity-90 m-auto pt-48">
            <span class=" text-white w-full text-4xl font-bold">Le temps est écoulé</span> <br> <br><br><br>
            <span class=" text-white w-full text-2xl font-semibold">Vos réponses ont bien été prises en compte, vous
                recevrez vos résultats sous 24h.</span> <br>
            <span class=" text-white w-full text-2xl font-semibold">Nous vous invitons à vous déconnecter en cliquant
                sur le bouton "Déconnexion".</span>
            <br> <br><br><br><br><br>
            <button @click="logOut()"
                class=" bg-blue-700 text-white hover:bg-white hover:text-blue-700 font-bold py-2 px-4 rounded-full w-52 focus:outline-none focus:shadow-outline"
                type="button">
                Déconnexion
            </button>
        </div>

    </div>


</template>

<script>
import CardMenu from "@/components/menu/CardMenu.vue";
import Cookies from 'js-cookie';
export default {
    name: 'EndMenu',
    components: { CardMenu },
    methods: {
        supprCookie() {
            Cookies.remove('activityNumber')
            Cookies.remove('qcmStep')
            Cookies.remove('finishTime')
        },
        logOut: function () {
            this.$store.dispatch('logout', {
            })
        },
    },
    beforeMount() {
        this.supprCookie()
    },
}
</script>

<style scoped>
@media screen and (max-height: 700px) {
    .title {
        font-size: 1.2rem;
        line-height: 2rem;
    }

    .title-cont {
        margin-bottom: 0rem;
        margin-top: 0.5rem;
    }
}

.title-cont {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.btnPart {
    min-width: 40rem;
    max-width: 60rem;
}
</style>