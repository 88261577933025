<template>
    <div class="bg-gradient-to-r from-gradientTop to-gradientBottom background-login">
        <LoginStart
            v-if="loginStatus === '' || loginStatus === 'loading' || loginStatus === 'error_login' || loginStatus === 'not_registred'" />
        <LoginInstructions v-else-if="loginStatus === 'loged-in-instructions'" />
        <LoginCertif v-else-if="loginStatus === 'loged-in-certif'" />
    </div>

</template>

<script>
import LoginStart from "@/components/login/LoginStart.vue";
import LoginInstructions from "@/components/login/LoginInstructions.vue";
import LoginCertif from "@/components/login/LoginCertif.vue";
import { mapState } from 'vuex'
import Cookies from 'js-cookie';
Cookies.remove('activityNumber')
Cookies.remove('qcmStep')
Cookies.remove('progression')
Cookies.remove('gameInd')
export default {
    name: 'LoginView',
    components: {
        LoginStart,
        LoginCertif,
        LoginInstructions
    },
    data: function () {
        return {
            username: '',
            password: ''
        }
    },
    methods: {
        getCookie: function () {
            this.$store.dispatch('getCookie', {
            })
        },
        logOut: function () {
            this.$store.dispatch('logout', {
            })
        },
    },
    computed: {
        ...mapState(['loginStatus', 'user'])
    },
    beforeMount() {
        this.getCookie()
    },
}
</script>

<style>
.login {
    padding: 1.9rem;
    max-width: 59rem;
    max-height: 40rem;
    min-height: 30rem;
}

.login-container {
    max-width: 59rem;
    padding-top: 5rem;
}

.background-login {
    height: 100vh;
}

@media (max-height: 650px) {
    .login-container {
        padding-top: 2rem;
    }

    /* .background-login {
            height: 100%;
        } */
}

@media (max-height: 610px) {
    .login-container {
        transform: scale(0.8);
    }
}

@media (max-height: 580px) {
    .login-container {
        transform: scale(0.6);
    }
}
</style>