<template>
  <router-view/>
</template>

<!-- <script>
  export default {
  created() {
      window.addEventListener('beforeunload', function(event) {
         event.returnValue = 'Write something'
      })
    },
  }
</script> -->

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
}
@media (  max-height: 550px ) {
  #app{
    overflow: auto;
  }
 }

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
