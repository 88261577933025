import { createStore } from 'vuex'
import router from '../router/index';
const axios = require('axios')
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/api/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    withCredentials: true
});

let formatDate = (date) => {
    var datePart = date.match(/\d+/g),
        year = datePart[0],
        month = datePart[1],
        day = datePart[2];

    return day + '/' + month + '/' + year;
}

let formatName = (last, first) => {
    return first + ' ' + last
}
export default createStore({
    state: {
        loginStatus: '',
        user: {
            fullName: '',
            id: "",
            username: '',
            firstname: "",
            lastname: "",
            birthdate: "",
            with_disability: "",
            session_attended_at: "",
            session: {
                id: '',
                status: "",
                date: null
            }
        },
        userSessionStatus: 'started',
        currentQuestionData: {
            questions: undefined,
            answers: undefined,
            activityType: undefined,
            activityNumber: '0',
            number: undefined,
        },
        userAnswer: '',
        qcmStepCount: 0,
        progression: 0,
        finishHour: '',
        gameActive: false,
        // gameInd: 0
    },
    getters: {},
    mutations: {
        setLoginStatus: function(state, status) {
            state.loginStatus = status;
        },
        setUser: function(state, user) {
            state.user.id = user.data.id
            state.user.username = user.data.username
            state.user.firstname = user.data.firstname
            state.user.lastname = user.data.lastname
            state.user.with_disability = user.data.with_disability
            state.user.fullName = formatName(user.data.firstname, user.data.lastname)
            state.user.birthdate = formatDate(user.data.birthdate)
            state.user.session_attended_at = user.data.session_attended_at
            state.user.session.id = user.data.session.id
            state.user.session.status = user.data.session.status
            state.user.session.date = user.data.session.date
        },
        setUserSession: function(state, sessionStatus) {
            state.userSessionStatus = sessionStatus
        },
        setGameActive: function(state, active) {
            state.gameActive = active
        },
        setCurrentQuestionData: function(state, gameData) {
            if (gameData !== undefined) {
                state.currentQuestionData.questions = gameData.data.questions
                state.currentQuestionData.answers = gameData.data.answers
                state.currentQuestionData.activityType = gameData.data.questions[0].module_class
                state.currentQuestionData.number = gameData.data.number
                if (gameData.data.questions[0].module_class === 'App\\Models\\Activity') {
                    state.currentQuestionData.activityNumber = gameData.data.questions[0].activity_number
                } else {
                    state.currentQuestionData.activityNumber = undefined
                }
            } else {
                state.currentQuestionData.questions = undefined
                state.currentQuestionData.answers = undefined
                state.currentQuestionData.activityType = undefined
                state.currentQuestionData.activityNumber = undefined
                state.currentQuestionData.number = undefined
            }
        },
        setUserAnswer: function(state, answersData) {
            state.userAnswer = answersData
        },
        setProgression: function(state) {
            state.progression = state.currentQuestionData.number + 1
        },
        setProgressionStart: function(state) {
            state.progression = state.currentQuestionData.number
        },
        setFinishTime: function(state, hour) {
            var c = Cookies.get('finishTime')
            if (c === undefined) {
                Cookies.set('finishTime', hour, { expires: 1 / 10 })
                state.finishHour = hour
            } else if (c !== undefined) {
                state.finishHour = c
            }
        },
    },
    actions: {
        getCookie: ({ commit }) => {
            commit
            var config = {
                method: 'get',
                url: process.env.VUE_APP_API_URL + '/sanctum/csrf-cookie',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            };
            axios(config)
                .then(function(response) {
                    return response
                })
                .catch(function(error) {
                    return error
                });
        },
        login: ({ commit }, userInfos) => {
            commit('setLoginStatus', 'loading');
            return new Promise((resolve, reject) => {
                instance.post('login', userInfos)
                    .then(function(response) {
                        commit('setLoginStatus', 'loged-in-instructions');
                        commit('setUser', response.data);
                        resolve(response);
                    })
                    .catch(function(error) {
                        console.log('login', error)
                        if (error.status === 401) {
                            commit('setLoginStatus', 'error_login');
                        } else {
                            commit('setLoginStatus', 'not_registred');
                        }
                        // if (error.status === 403) {
                        //     commit('setLoginStatus', 'not_registred');
                        // } else {
                        //     commit('setLoginStatus', 'error_login');
                        // }
                        reject(error);
                    });
            });
        },
        goToCertif: ({ commit }) => {
            commit('setLoginStatus', 'loged-in-certif');
        },
        addProgression: ({ commit }) => {
            commit('setProgression');
        },
        setStartProgression: ({ commit }) => {
            commit('setProgressionStart');
        },
        defineFinishTime: ({ commit }, hour) => {
            commit('setFinishTime', hour)
        },
        changeUserSessionStatus: ({ commit }, info) => {
            commit('setUserSession', info)
        },
        defineGameActive: ({ commit }, active) => {
            commit('setGameActive', active)
        },
        logout: ({ commit }) => {
            return new Promise((resolve, reject) => {
                instance.post('logout')
                    .then(function(response) {
                        commit('setLoginStatus', '');
                        resolve(response);
                        router.push('/')
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            });
        },
        getStarted: ({ commit }) => {
            return new Promise((resolve, reject) => {
                instance.post('sessions/start?confirm=1')
                    .then(function(response) {
                        commit('setUser', response.data);
                        resolve(response);
                    })
                    .catch(function(error) {

                        reject(error);
                    });
            });
        },
        getCurrentUser: ({ commit }) => {
            return new Promise((resolve, reject) => {
                instance.get('me')
                    .then(function(response) {
                        commit('setUser', response.data);
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            });
        },
        getCurrentQuestionData: ({ commit, state }) => {
            return new Promise((resolve, reject) => {
                instance.get('sessions/current')
                    .then(function(response) {
                        if (response.status === 204) {
                            if (state.currentQuestionData.number < 49) {
                                commit('setCurrentQuestionData', undefined)
                                commit('setUserSession', 'timeOut');
                                router.push('/menu')
                            } else {
                                commit('setCurrentQuestionData', undefined)
                                commit('setUserSession', 'finished');
                                router.push('/menu')
                            }
                        } else {
                            commit('setUserSession', 'started');
                            commit('setCurrentQuestionData', response.data);
                        }
                        resolve(response);
                    })
                    .catch(function(error) {
                        console.log(error)
                        commit('setCurrentQuestionData', undefined)
                        reject(error);
                    });
            });
        },
        sendUserAnswer: ({ commit, state }, infos) => {
            let formatData = infos.activityAnswer
            commit('setUserAnswer', formatData)
            return new Promise((resolve, reject) => {
                instance.post('sessions/answer', formatData)
                    .then(function(response) {
                        if (response.status === 204) {
                            if (state.currentQuestionData.number < 49) {
                                commit('setCurrentQuestionData', undefined)
                                commit('setUserSession', 'timeOut');
                                router.push('/menu')
                            } else {
                                commit('setCurrentQuestionData', undefined)
                                commit('setUserSession', 'finished');
                                router.push('/menu')
                            }
                        } else {
                            commit('setCurrentQuestionData', response.data);
                        }
                        resolve(response);
                    })
                    .catch(function(error) {
                        reject(error);
                    });
            });
        },
    }
})