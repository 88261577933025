<template>
  <div class="w-5/6 flex justify-around items-end m-auto login-container">
    <div>
      <h1 class="text-4xl text-white font-bold">CCPM Classification</h1>
    </div>
    <div class="login w-full bg-white rounded text-left p-4 justify-self-end">
      <h1 class="text-loginBlue font-bold text-3xl pb-5">Déclaration sur l'honneur</h1>
      <h3 class="font-light text-base text-loginBlue ">Candidat :</h3>
      <span class="mb-2 text-lg">{{ this.user.fullName }}</span>
      <h3 class="font-light text-base mt-3 text-loginBlue ">Date de naissance :</h3>
      <span class="mb-2 text-lg">{{ this.user.birthdate }}</span>
      <p class=" text-sm mt-6">Cliquez sur la case ci-dessous pour confirmer votre identité :</p>
      <div class="flex items-center mt-3 mb-10">
        <input id="link-checkbox" type="checkbox" v-model="isCertif"
          class="w-8 h-4 text-blue-600 bg-gray-100 rounded border-gray-300  dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600 justify-self-start">
        <label for="link-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Je certifie sur
          l’honneur l’exactitude des renseignements fournis ci-dessus, et reconnais que toute fausse déclaration
          entraînera
          l’annulation automatique des mes résultats à la session de passation du Certificat de Compétences
          Professionnelles
          de la Métallurgie - Classification. </label>
      </div>
      <div class="flex">

        <button v-if=!btnStateSeter
          class="bg-loginBlue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-auto "
          @click="getStarted()" type="button">
          Commencer le CCPM Classification
        </button>
        <button v-else-if=btnStateSeter class="bg-gray-200 text-gray-500 font-bold py-2 px-4 m-auto rounded-full"
          type="button">
          Commencer le CCPM Classification
        </button>
      </div>
      <p class="text-base pt-3 text-loginBlue text-center font-semibold">L’épreuve commencera quand<br>votre
        examinatrice.teur vous
        l’indiquera.</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'LoginCertif',
  data: function () {
    return {
      isCertif: false,
    }
  },
  methods: {
    getStarted: function () {
      const self = this
      this.$store.dispatch('getStarted', {})
        .then(function () {
          self.$router.push('/menu');
          self.$store.dispatch('changeUserSessionStatus', 'started')
          self.setEndHour()
        }, function (error) {
          self.$router.push('/menu');
          console.log(error);
          self.$store.dispatch('changeUserSessionStatus', 'wait')
        })
    },
    setEndHour() {
      var hour
      if (this.user.with_disability) {
        hour = dayjs().add(2.5, 'hour').format('HH:mm');
      } else {
        hour = dayjs().add(2, 'hour').format('HH:mm');
      }
      this.$store.dispatch('defineFinishTime', hour)
    },
  },
  computed: {
    btnStateSeter() {
      if (this.isCertif !== true) {
        return true
      } else {
        return false
      }
    },
    ...mapState(['user'])
  }
}
</script>

<style scoped>
  #link-checkbox {
    float: left;
  }
  
  label {
    float: left;
    width: 100%;
  }
  
  .login {
    max-width: 25rem;
  }
  </style>
